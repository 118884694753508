import { createAsyncThunk } from '@reduxjs/toolkit';

import Axios from '../../../../axiosSetup';
import { AxiosError } from 'axios';

import queryString from 'query-string';
import { toast } from 'react-toastify';
import { AESEncryptforDashboard } from '../../../../encrypt-util';
import { AESDecrypt } from '../../../../encrypt-util';
import {axiosCacheInstance} from '../../../../axiosSetup';

export const getBlsList = createAsyncThunk(
  'dashboard/getAllList',
  async (seekQuery?: any | undefined) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?${queryString.stringify({
          ...seekQuery,
          size: 20,
        })}`
      );

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getBlsListExportCSV = createAsyncThunk(
  'dashboard/getBlsListExportCSV',
  async () => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/sendBlCsvMail?size=1&sort=consigneeNm,ASC&direction=reverse${queryString.stringify(
          {
            size: 1000,
          }
        )}`
      );
      toast.success(response.data.message, {
        style: {
          color: 'white',
        },
      });

      return {};
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const getInvoiceListExportCSV = createAsyncThunk(
  'dashboard/getInvoiceListExportCSV',
  async () => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/invoice/emailCsv?size=1&sort=consigneeNm,ASC&direction=reverse${queryString.stringify(
          {
            size: 1000,
          }
        )}`
      );
      toast.success(response.data.message, {
        style: {
          color: 'white',
        },
      });
      return {};
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const getInvoiceList = createAsyncThunk(
  'invoicedashboard/getAllList',
  async (seekQuery?: any | undefined) => {
    try {
      const response = await Axios.post(
        `/api/invoice-management/v1/invoice/listInvoiceByQuery`,
        {
          ...seekQuery,
          limit: '20',
          sorts: seekQuery?.sort ? [seekQuery?.sort] : [],
          fields: [],
        }
      );
      if (seekQuery?.paidIndicator == '1') {
        const filteredData = {
          ...response.data,
          list: response.data.list.filter(
            (item: any) => item.paidIndicator === '1'
          ),
        };
        return filteredData;
      }
      if (response?.data?.list) {
        return response.data;
      }

      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListFilter = createAsyncThunk(
  'invoicedashboard/getInvoiceListFilter',
  async (filterData: any) => {
    try {
      const filterString = queryString.stringify(
        {
          ...filterData,

          time: undefined,
        },
        { skipEmptyString: true }
      );

      const response = await Axios.post(
        `/api/invoice-management/v1/invoice/listInvoiceByQuery`,
        {
          filters: {},
          sorts: [],
          seek: '',
          direction: '',
          limit: '20',
          search: '',
        }
      );

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListWithBlnumber = createAsyncThunk(
  'invoicedashboard/getInvoiceListWithBlnumber',
  async (blNumber: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/invoice?blNumber=${blNumber}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          blNumber: v?.blNumber || null,
          blId: v?.blId || null,
          invoiceNumber: v?.number || null,
          invoiceType: v?.type || null,
          end: v?.finalPod || null,
          time: v?.lastUpdateDate || null,
          consignee: v?.consigneeNm || null,
          vessel: v?.vesselName || null,
          due: v?.etd || null,
          pmt_reference: v?.paymentRefNo || null,
          paidIndicator: v?.paidIndicator || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListWithSearch = createAsyncThunk(
  'invoicedashboard/getInvoiceListWithSearch',
  async (searchInputValue: any) => {
    try {
      const response = await Axios.post(
        '/api/invoice-management/v1/invoice/listInvoiceByQuery',
        {
          fields: [],
          filters: searchInputValue,

          sorts: [],
          limit: '20',
          search: '',
        }
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListWithShipContainerNo = createAsyncThunk(
  'invoicedashboard/getInvoiceListWithShipContainerNo',
  async ({
    searchInputValue,
    type,
  }: {
    searchInputValue: string;
    type: string;
  }) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/coreor/${type}/invoice/${searchInputValue}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          number: v?.number || null,
          ccy: v?.ccy || null,
          invDate: v?.invDate || null,
          invoiceType: v?.type || null,
          blNumber: v?.blNumber || null,
          blId: v?.blId || null,
          end: v?.finalPod || null,
          time: v?.lastUpdateDate || null,
          consignee: v?.consigneeNm || null,
          instruction: v?.instructionStatus || null,
          release: v?.releaseStatus || null,
          vessel: v?.vesselName || null,
          due: v?.etd || null,
          amount: v?.amount || null,
          paidIndicator: v?.paidIndicator || null,
          dueDate: v?.dueDate || null,
          paymentRefNo: v?.paymentRefNo || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getDashboardData = createAsyncThunk(
  'dashboard/getDashboardData',
  async (params: any, {getState}:any) => {
    const searchInputValue = getState().navbarTop?.searchInputValue
    // NOTE the liner ID is not being used by the backend API point
    // It is being added for the frontend caching to distinguish between
    // view calls from different liner selections
    const liner = getState().navbarTop?.linerId
    var whereClause = '';
    params.parameter.searchParameter = searchInputValue
    if (
      params?.parameter?.searchWhere?.includes('#PARAM#') &&
      params?.parameter?.searchParameter
    ) {
      if (whereClause !== '') {
        whereClause = whereClause.concat(' AND ');
      }

      whereClause = whereClause.concat(
        (params?.parameter?.searchWhere as string).replaceAll(
          '#PARAM#',
          params?.parameter?.searchParameter
        )
      );
    }
    if (params.partnerId) {
      if (whereClause != '') {
        whereClause = whereClause.concat(' AND ');
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll('#PARTNER_ID#', params.partnerId)
      );

      whereClause = whereClause.replaceAll(
        '#VIEW_NM#',
        'vw_' + params?.gridName?.toLowerCase()
      );
      if(params.where){
        whereClause = whereClause.replaceAll('#COUNTRY#', params.dataCountry)
        
      }
    }

    if (!params.partnerId && params.where) {
      if (whereClause != '') {
        whereClause = whereClause.concat(' AND ');
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll('#PARAM#', params.dataCountry)
      );
    }
    // debugger;
    try {
      if (params.where != null || '') {
        let link = '/api/invoice-management/v1/view'
        if (params?.v2){
          link = '/api/edo-core/v2/view'
        }
        const response = await axiosCacheInstance.post(link, {
          ...params.payload,
          view: AESEncryptforDashboard('vw_' + params?.gridName?.toLowerCase()),
          where: AESEncryptforDashboard(whereClause),
          asCsv: params?.asCsv,
          // NOTE: this linerId does nothing in the backend and is only added
          // for the frontend Axios caching to distinguish between API calls
          // to avoid ommiting valid API calls
          linerId: liner,
          v2:undefined
        });

        if (response?.data?.result)
          return { gridName: params?.gridName, data: response.data };
        return {
          gridName: params?.gridName,
          data: {
            maxPage: null,
            size: null,
            result: [],
            asCsv: params?.asCsv,
          },
        };
      }
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;

      return {
        gridName: params?.gridName,
        data: {
          maxPage: null,
          size: null,
          result: [],
        },
      };
    }
  }
);

export const getInstructionList = createAsyncThunk(
  'instructions/getInstructionList',
  async (seekQuery?: any | undefined) => {
    try {
      /* const response = await Axios.get(
        `/api/invoice-management/v1/invoice?${queryString.stringify({
          ...seekQuery,
          size: 20,
        })}`
      ); */

      const response = await Axios.post(
        `/api/invoice-management/v1/instructions/listInstructions`,
        {
          ...seekQuery,
          limit: '20',
          sorts: seekQuery?.sort ? [seekQuery?.sort] : [],
          fields: [],
        }
      );
      if (seekQuery?.paidIndicator == '1') {
        const filteredData = {
          ...response.data,
          list: response.data.list.filter(
            (item: any) => item.paidIndicator === '1'
          ),
        };
        return filteredData;
      } /* else if (seekQuery?.paidIndicator == '2') {
        const filteredData = {
          ...response.data,
          list: response.data?.list?.filter(
            (item: any) =>
              item.paidIndicator === '' || item.paidIndicator === null
          ),
        };

        return filteredData;
      }
 */
      if (response?.data?.list) {
        return response.data;
      }

      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInstructionListWithBlnumber = createAsyncThunk(
  'instructions/getInstructionListWithBlnumber',
  async (searchInputValue: any) => {
    try {
      const response = await Axios.post(
        `/api/invoice-management/v1/instructions/listInstructions`,
        {
          fields: [],
          filters: searchInputValue,

          sorts: [],
          limit: '20',
          search: '',
        }
      );
      if (response?.data?.list) return response.data;
      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInstructionsListFilter = createAsyncThunk(
  'instructions/listInstructions',
  async (filterData?: any | undefined) => {
    let payloadString: any = {};
    for (let k of Object.keys(filterData)) {
      if (typeof filterData[k] && filterData[k]?.length > 1) {
        payloadString[k] = filterData[k].toString();
      } else {
        payloadString[k] = filterData[k];
      }
    }
    try {
      const response = await Axios.post(
        `/api/invoice-management/v1/instructions/listInstructions`,
        {
          ...filterData,
          limit: '20',
          sorts: filterData?.sort ? [filterData?.sort] : [],
          //sorts: filterData?.sort ? [filterData?.sort] : ["lastUpdatedDate,asc"],
          fields: [],
        }
      );
      if (response?.data?.list) return response.data;
      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const searchWithBlno = createAsyncThunk(
  'dashboard/searchWithBlno',
  async (blNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?blNo=${blNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          delegatedTo: v?.delegatedTo || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ''}${
            v?.vesselName && v?.voyageNo ? ' - ' : ''
          }${v?.voyageNo || ''}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : '',

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const searchWithShipmentNoBlDashboard = createAsyncThunk(
  'dashboard/searchWithShipmentNoBlDashboard',
  async (blNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?shipmentNo=${blNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          delegatedTo: v?.delegatedTo || null,
          shipmentNo: v?.shipmentNo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ''}${
            v?.vesselName && v?.voyageNo ? ' - ' : ''
          }${v?.voyageNo || ''}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : '',

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const searchWithInternalVoyageNo = createAsyncThunk(
  'dashboard/searchWithInternalVoyageNo',
  async (internalVoyageNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?internalVoyageNo=${internalVoyageNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          delegatedTo: v?.delegatedTo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ''}${
            v?.vesselName && v?.voyageNo ? ' - ' : ''
          }${v?.voyageNo || ''}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : '',

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const searchWithShipmentno = createAsyncThunk(
  'dashboard/searchWithShipmentno',
  async (blNo: any) => {
    try {
      const response = await Axios.get(`/api/invoice-management/v1/bl/${blNo}`);

      return {
        ...response.data,
        list: [response.data].map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          delegatedTo: v?.delegatedTo || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ''}${
            v?.vesselName && v?.voyageNo ? ' - ' : ''
          }${v?.voyageNo || ''}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : '',

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const searchWithContainerno = createAsyncThunk(
  'dashboard/searchWithContainerno',
  async (containerNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/coreor/container/${containerNo} `
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          delegatedTo: v?.delegatedTo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ''}${
            v?.vesselName && v?.voyageNo ? ' - ' : ''
          }${v?.voyageNo || ''}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : '',

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getPaymentList = createAsyncThunk(
  'dashboard/getAllList',
  async (seekQuery?: any | undefined) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?${queryString.stringify({
          ...seekQuery,
          size: 20,
        })}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,

          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ''}${
            v?.vesselName && v?.voyageNo ? ' - ' : ''
          }${v?.voyageNo || ''}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : '',
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const searchWithInternalVoyageNoPaymentDashboard = createAsyncThunk(
  'invoicedashboard/searchWithInternalVoyageNoPaymentDashboard',
  async (internalVoyageNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/invoice/voyage/${internalVoyageNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          blNumber: v?.blNumber || null,
          blId: v?.blId || null,
          invoiceNumber: v?.number || null,
          invoiceType: v?.type || null,
          end: v?.finalPod || null,
          time: v?.lastUpdateDate || null,
          consignee: v?.consigneeNm || null,
          vessel: v?.vesselName || null,
          due: v?.etd || null,
          pmt_reference: v?.paymentRefNo || null,
          paidIndicator: v?.paidIndicator || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getBlByBlNo = createAsyncThunk(
  'bl/getBlByBlNo',
  async (blNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/getBlByBlNo?blNo=${blNo}`
      );
      if (response?.data) {
        return response.data;
      } else {
        return {};
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {};
    }
  }
);

export const getUnassignedBlByBlNo = createAsyncThunk(
  'bl/getUnassignedBlByBlNo',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/getUnassignedBlByBlNo?blNo=${payload?.blNo}&type=${payload.type}`
      );
      if (response?.data) {
        return response.data;
      } else {
        return {};
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {};
    }
  }
);

export const exportDashboardData = createAsyncThunk(
  'dashboard/exportDashboardData',
  async (params: any) => {
    var whereClause = '';

    if (
      params?.parameter?.searchWhere?.includes('#PARAM#') &&
      params?.parameter?.searchParameter
    ) {
      if (whereClause !== '') {
        whereClause = whereClause.concat(' AND ');
      }

      whereClause = whereClause.concat(
        (params?.parameter?.searchWhere as string).replaceAll(
          '#PARAM#',
          params?.parameter?.searchParameter
        )
      );
    }
    if (params.partnerId) {
      if (whereClause != '') {
        whereClause = whereClause.concat(' AND ');
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll('#PARTNER_ID#', params.partnerId)
      );

      whereClause = whereClause.replaceAll(
        '#VIEW_NM#',
        'vw_' + params?.gridName?.toLowerCase()
      );
    }

    if (!params.partnerId && params.where) {
      if (whereClause != '') {
        whereClause = whereClause.concat(' AND ');
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll('#PARAM#', params.dataCountry)
      );
    }
    // debugger;
    try {
      // if (params.dataCountry != null) {
      const response = await Axios.post(`/api/invoice-management/v1/view`, {
        ...params.payload,
        view: AESEncryptforDashboard('vw_' + params?.gridName?.toLowerCase()),
        where: AESEncryptforDashboard(whereClause),
        asCsv: params?.asCsv,
      });

      // if (response?.data?.result) {
      //   toast.success('CSV file has been shared successfully to your email ID.');
      //   // toast('Mail has been Send Successfuly');
      // }
      if (response?.data?.result) return response?.data?.result;
      /*   if (response?.data?.result)
        return { gridName: params?.gridName, data: response.data };
      return {
        gridName: params?.gridName,
        data: {
          maxPage: null,
          size: null,
          result: [],
          asCsv: params?.asCsv,
        },
      }; */
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;

      /*  return {
        gridName: params?.gridName,
        data: {
          maxPage: null,
          size: null,
          result: [],
        },
      }; */
    }
  }
);
